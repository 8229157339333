<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <vx-input v-model="search.text" type="text" name="text" label="Search" @change="getFiles()" />
          </v-col>

          <v-col cols="12" sm="4">
            <vx-btn color="primary" @click="$refs.uploader.openModal()"> Upload Media</vx-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="(file, index) in files" :key="index" cols="3">
            <v-card hover outlined @click="$copy(file.url)">
              <vx-img class="rounded cursor-pointer" size="400" square :src="$getImage(file.url, 800)" />
              <v-card-title :title="file.name" class="text-subtitle-1 text-overflow">
                {{ file.name }}
              </v-card-title>

              <v-card-subtitle class="justify-space-between d-flex pb-1">
                <vx-btn icon @click.stop="$view('file', file._id)">
                  <vx-icon>mdi-eye</vx-icon>
                </vx-btn>
                <vx-btn icon @click.stop="$edit('file', file._id)">
                  <vx-icon>mdi-pencil</vx-icon>
                </vx-btn>
                <vx-btn icon @click.stop="$copy(file.url)">
                  <vx-icon>mdi-content-copy</vx-icon>
                </vx-btn>
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12">
            <vx-pagination v-model="pagination" @change="getFiles()" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <FileUploader ref="uploader" :options="fileUploaderOptions" @close="getFiles()" />
  </div>
</template>

<script>
import { FileService } from '@tutti/services';
import FileUploader from '@tutti/components/FileUploader';

export default {
  name: 'AddFile',

  components: {
    FileUploader,
  },

  props: {
    value: { type: Boolean, required: true },
  },

  data() {
    return {
      pagination: {
        page: 1,
        limit: 12,
        count: 0,
      },
      files: [],
      search: {
        text: '',
      },
      fileUploaderOptions: {
        headers: {
          authorization: this.$cookies.get(process.env.VUE_APP_AUTH_TOKEN_KEY),
          partners: false,
          admin: true,
        },
      },
    };
  },

  async created() {
    await this.getFiles();
    console.log(this.$cookies.get(process.env.VUE_APP_AUTH_TOKEN_KEY), 'sss');
  },

  methods: {
    async getFiles() {
      const response = await FileService.getAll({
        page: this.pagination.page,
        limit: this.pagination.limit,
        search: this.search,
        filter: {
          from: 'cms',
        },
      });

      if (response) {
        this.files = response.data.data;
        this.pagination.count = response.data.count;
      }
    },
  },
};
</script>
