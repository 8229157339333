var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "text",
      "label": "Search"
    },
    on: {
      "change": function change($event) {
        return _vm.getFiles();
      }
    },
    model: {
      value: _vm.search.text,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "text", $$v);
      },
      expression: "search.text"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('vx-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.uploader.openModal();
      }
    }
  }, [_vm._v(" Upload Media")])], 1)], 1), _c('v-row', [_vm._l(_vm.files, function (file, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "hover": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$copy(file.url);
        }
      }
    }, [_c('vx-img', {
      staticClass: "rounded cursor-pointer",
      attrs: {
        "size": "400",
        "square": "",
        "src": _vm.$getImage(file.url, 800)
      }
    }), _c('v-card-title', {
      staticClass: "text-subtitle-1 text-overflow",
      attrs: {
        "title": file.name
      }
    }, [_vm._v(" " + _vm._s(file.name) + " ")]), _c('v-card-subtitle', {
      staticClass: "justify-space-between d-flex pb-1"
    }, [_c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$view('file', file._id);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-eye")])], 1), _c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$edit('file', file._id);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-pencil")])], 1), _c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$copy(file.url);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-content-copy")])], 1)], 1)], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-pagination', {
    on: {
      "change": function change($event) {
        return _vm.getFiles();
      }
    },
    model: {
      value: _vm.pagination,
      callback: function callback($$v) {
        _vm.pagination = $$v;
      },
      expression: "pagination"
    }
  })], 1)], 2)], 1)], 1), _c('FileUploader', {
    ref: "uploader",
    attrs: {
      "options": _vm.fileUploaderOptions
    },
    on: {
      "close": function close($event) {
        return _vm.getFiles();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }